/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';
// import request from "../utils/api";
import { enquireScreen } from 'enquire-js';

// import Feature7 from './Feature7';
import Feature8 from './Feature8';
import Teams1 from './Teams1';
// import Feature7 from './Feature7';

import {
  Feature80DataSource,
  Teams11DataSource,
  // Feature70DataSource,
} from '../utils/company.source';
import './less/antMotionStyle.less';

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

const { location = {} } = typeof window !== 'undefined' ? window : {};

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
      show: !location.port, // 如果不是 dva 2.0 请删除
      contentData: {}
    };
  }

  componentDidMount() {
    // 适配手机屏幕;
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
    // dva 2.0 样式在组件渲染之后动态加载，导致滚动组件不生效；线上不影响；
    /* 如果不是 dva 2.0 请删除 start */
    if (location.port) {
      // 样式 build 时间在 200-300ms 之间;
      setTimeout(() => {
        this.setState({
          show: true,
        });
      }, 500);
    }
    /* 如果不是 dva 2.0 请删除 end */
  }
  render() {
    const children = [
      <div className="company-wrapper" key="Company_banner">
        <div className="company-banner">
          <div className="company-banner-cover"></div>
          <div className="company-banner-wrapper">
            <div className="company-banner-text">
              LET'S HELP<br />EVERYONE FLY
            </div>
          </div>
        </div>
      </div>,
      <div className="company-wrapper" key="Company_content_intro">
        <div className="company-content">
          <div className="company-content-list">
            <Feature8
              id="Feature8_0"
              key="Feature8_0"
              dataSource={Feature80DataSource}
              isMobile={this.state.isMobile}
            />
          </div>
        </div>
      </div>,
      <div className="company-wrapper" style={{background: '#f9f9f9'}} key="Company_content_team">
        <div className="company-content">
          <div className="company-content-list">
            <Teams1
              id="Teams1_1"
              key="Teams1_1"
              dataSource={Teams11DataSource}
              isMobile={this.state.isMobile}
            />
          </div>
        </div>
      </div>,
    //   <div className="company-wrapper" key="Company_content_qa">
    //   <div className="company-content">
    //     <div className="company-content-list">
    //       <Feature7
    //         id="Feature7_0"
    //         key="Feature7_0"
    //         dataSource={Feature70DataSource}
    //         isMobile={this.state.isMobile}
    //       />
    //     </div>
    //   </div>
    // </div>
    ];
    return (
      <div
        className="templates-wrapper"
        ref={(d) => {
          this.dom = d;
        }}
      >
        {/* 如果不是 dva 2.0 替换成 {children} start */}
        {this.state.show && children}
        {/* 如果不是 dva 2.0 替换成 {children} end */}
      </div>
    );
  }
}
