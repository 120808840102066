import React from 'react';

import linkedin from '../Company/assets/linkedin.png';

import compImg1 from '../Company/assets/company-introduction-1.jpg';
import compImg2 from '../Company/assets/company-introduction-2.jpg';
import compImg3 from '../Company/assets/company-introduction-3.jpg';
import compImg4 from '../Company/assets/company-introduction-4.jpg';

import teamImg1 from '../Company/assets/team-introduction-1.jpg';
import teamImg2 from '../Company/assets/team-introduction-2.jpg';
import teamImg3 from '../Company/assets/team-introduction-3.jpg';
import teamImg4 from '../Company/assets/team-introduction-4.jpg';
import teamImg5 from '../Company/assets/team-introduction-5.jpg';
import teamImg6 from '../Company/assets/team-introduction-6.jpg';
/* eslint-disable */
import teamImg7 from '../Company/assets/team-introduction-7.jpg';
import teamImg8 from '../Company/assets/team-introduction-8.jpg';
/* eslint-enable */
import teamImg9 from '../Company/assets/team-introduction-9.jpg';

export const Feature80DataSource = {
  wrapper: { className: 'home-page-wrapper compf8-wrapper' },
  page: { className: 'home-page compf8' },
  OverPack: { className: 'compf8-carousel-wrapper' },
  titleWrapper: {
    className: 'compf8-title-wrapper',
    children: [
      { name: 'title', className: 'compf8-title-h1', children: 'COMPANY INTRODUCTION' },
      {
        name: 'bottomline',
        className: 'compf8-title-bottomline',
        children: 'bottomline',
      }
    ]
  },
  childWrapper: {
    className: 'compf8-title-block',
    children: [{
        name: 'content',
        className: 'compf8-title-content',
        children: (<div><strong>Letsfly</strong> began its business in 2014 as a technology-based enterprise dedicated to integrating and distributing global travel content and resources. Since its inception, Letsfly has been committed to offering an advanced and reliable distribution platform to assist travel partners in delivering the most suitable airfare and hotel products to global travelers with high quality and efficiency. This commitment has helped Letsfly establish a leading position in the world's LCC and NDC distribution market.</div>),
      },
      {
        name: 'content',
        className: 'compf8-title-content',
        children: 'Headquartered in Beijing, with offices in Dalian, Hong Kong, Singapore, Sydney, and Dublin. Letsfly has established deep and creative partnerships with global OTA giants and has successfully connected with over 400,000 international hotels and 124+ airlines, including 50+ low-cost carriers through direct connections, as well as NDC airlines. Letsfly processes more than 400 million search requests per day, making it a highly sought-after solution in the travel industry.',
      },
    ],
  },
  Carousel: {
    dots: false,
    className: 'compf8-carousel',
    wrapper: { className: 'compf8-block-wrapper' },
    children: {
      className: 'compf8-block',
      titleWrapper: {
        className: 'compf8-carousel-title-wrapper',
        title: { className: 'compf8-carousel-title' },
      },
      children: [
        {
          name: 'block0',
          className: 'compf8-block-row',
          gutter: 12,
          title: {
            className: 'compf8-carousel-title-block',
            children: '平台自主训练流程',
          },
          children: [
            {
              className: 'compf8-block-col',
              md: 24,
              xs: 24,
              name: 'child0',
              children: {
                className: 'compf8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'compf8-block-image',
                    children: compImg1,
                  },
                ],
              },
            }
          ],
        },
        {
          name: 'block1',
          className: 'compf8-block-row',
          gutter: 12,
          title: {
            children: '平台自主训练流程',
            className: 'compf8-carousel-title-block',
          },
          children: [
            {
              className: 'compf8-block-col',
              md: 24,
              xs: 24,
              name: 'child1',
              children: {
                className: 'compf8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'compf8-block-image',
                    children: compImg2,
                  },
                ],
              },
            }
          ],
        },
        {
          name: 'block2',
          className: 'compf8-block-row',
          gutter: 12,
          title: {
            children: '平台自主训练流程',
            className: 'compf8-carousel-title-block',
          },
          children: [
            {
              className: 'compf8-block-col',
              md: 24,
              xs: 24,
              name: 'child2',
              children: {
                className: 'compf8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'compf8-block-image',
                    children: compImg3,
                  },
                ],
              },
            }
          ],
        },
        {
          name: 'block3',
          className: 'compf8-block-row',
          gutter: 12,
          title: {
            children: '平台自主训练流程',
            className: 'compf8-carousel-title-block',
          },
          children: [
            {
              className: 'compf8-block-col',
              md: 24,
              xs: 24,
              name: 'child3',
              children: {
                className: 'compf8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'compf8-block-image',
                    children: compImg4,
                  },
                ],
              },
            }
          ],
        },
      ],
    },
  },
};
export const Teams11DataSource = {
  wrapper: { className: 'home-page-wrapper teams1-wrapper' },
  page: { className: 'home-page teams1' },
  OverPack: { className: 'teams1-container' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [{ name: 'title', children: 'TEAM INTRODUCTION' }],
  },
  block: {
    className: 'block-wrapper',
    gutter: 34,
    children: [
      {
        name: 'block5',
        className: 'block',
        lg: 6, 
        md: 8,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-image',
              children: teamImg6,
            },
            { name: 'title', className: 'teams1-title', children: "Gordon Liu" },
            {
              name: 'content',
              className: 'teams1-job',
              children: 'Letsfly Chief Executive Officer',
            },
            {
              name: 'content1',
              className: 'teams1-content',
              children:
                "Gordon Liu is group CEO of Letsfly, has over a decade of experience in internet entrepreneurship and the travel industry. ",
            },
            {
              name: 'content1',
              className: 'teams1-content',
              children:
                "With extensive expertise in technology and team leadership, he is responsible for developing group strategies, business plans, and daily operations.",
            },
            {
              name: 'content1',
              className: 'teams1-content',
              children:
                "Through ongoing innovation and growth, he drives the success of the company. His leadership has helped the company achieve significant milestones and establish a strong position in the industry.",
            }
          ],
        },
        linksWrapper: {
          children: 'https://www.linkedin.com/in/gordon-liu-94323252/'
        }
      },
      // {
      //   name: 'block6',
      //   className: 'block',
      //   lg: 6, 
      //   md: 8,
      //   xs: 24,
      //   titleWrapper: {
      //     children: [
      //       {
      //         name: 'image',
      //         className: 'teams1-image',
      //         children: teamImg7,
      //       },
      //       { name: 'title', className: 'teams1-title', children: "Dong Xu" },
      //       {
      //         name: 'content',
      //         className: 'teams1-job',
      //         children: 'CEO of Hotel business',
      //       },
      //       {
      //         name: 'content1',
      //         className: 'teams1-content',
      //         children:
      //           "sth",
      //       }
      //     ],
      //   },
      // },
      {
        name: 'block0',
        className: 'block',
        lg: 6, 
        md: 8,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-image',
              children: teamImg1,
            },
            { name: 'title', className: 'teams1-title', children: 'Kelvin Fu' },
            {
              name: 'content',
              className: 'teams1-job',
              children: 'Co-founder',
            },
            {
              name: 'content1',
              className: 'teams1-content',
              children:
                'With years of experience in travel technology and computer science background, Kelvin has a deep understanding with travel industry in flight / accommodation pricing and distribution.',
            },
            {
              name: 'content1',
              className: 'teams1-content',
              children:
                'He worked for IBM, Fujitsu, and also performed as Head Of Product for RenRen Inc international Unit.',
            },
            {
              name: 'content1',
              className: 'teams1-content',
              children:
                'Kelvin has successfully helped Letsfly Tech developed several new business units and gain great revenue increase with a high speed. He is open-minded to build a win-win partnership and eager to bring value to all stakeholders.',
            },
          ],
        },
        linksWrapper: {
          children: 'https://www.linkedin.com/in/kelvin-fu/'
        }
      },
      {
        name: 'block9',
        className: 'block',
        lg: 6, 
        md: 8,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-image',
              children: teamImg9,
            },
            { name: 'title', className: 'teams1-title', children: 'Ming Foong' },
            {
              name: 'content',
              className: 'teams1-job',
              children: 'Chief Commercial Officer',
            },
            {
              name: 'content1',
              className: 'teams1-content',
              children:
                'Ming is an experienced professional in eCommerce and technology industries, with over 20 years in roles spanning strategic planning, M&A, commercial, and category management. He headed the Asia region for Travelport and acted as Senior Advisor to Fliggy/AliTrip. Prior to these, Ming was with Microsoft/MSN and eBay.',
            },
            {
              name: 'content1',
              className: 'teams1-content',
              children:
                'As Co-founder and Chief Commercial Officer at Letsfly, Ming is committed to driving innovation and making a meaningful impact in the travel industry.',
            },
            {
              name: 'content1',
              className: 'teams1-content',
              children:
                'In his free time, Ming enjoys traveling and exploring different cultures through their food, art, and history.',
            },
          ],
        },
        linksWrapper: {
          children: 'https://www.linkedin.com/in/ming-foong/  '
        }
      },
      {
        name: 'block1',
        className: 'block',
        lg: 6, 
        md: 8,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-image',
              children: teamImg2,
            },
            { name: 'title', className: 'teams1-title', children: 'Ivan Ordas Villamandos' },
            {
              name: 'content',
              className: 'teams1-job',
              children: 'Director of Business Development Europe',
            },
            {
              name: 'content1',
              className: 'teams1-content',
              children:
                'Ivan has been working in airline industry  for more than 11 years , with a rich experience in Distribution and Partnership development.',
            },
            {
              name: 'content1',
              className: 'teams1-content',
              children:
                'Skilled in business strategy and travel technology，He had successfully built up solid business relationship with multiple distribution partners during his working for Blue Air Aviation, Vueling Airlines.',
            },
            {
              name: 'content1',
              className: 'teams1-content',
              children:
                'Ivan is now leading Letsfly Tech expansion in Europe to support partners on seamless integration and business driven.',
            },
          ],
        },
        linksWrapper: {
          children: 'https://www.linkedin.com/in/ivanordas/'
        }
      },
      {
        name: 'block3',
        className: 'block',
        lg: 6, 
        md: 8,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-image',
              children: teamImg4,
            },
            { name: 'title', className: 'teams1-title', children: "Emily Zhu" },
            {
              name: 'content',
              className: 'teams1-job',
              children: 'Director of Partner Success',
            },
            {
              name: 'content1',
              className: 'teams1-content',
              children:
                "Emily has over 10 years working experiences in airline retailing and travel industry , with an extensive experience covering business development, partnering, product and operations.",
            },
            {
              name: 'content1',
              className: 'teams1-content',
              children:
                "She has worked for All Nippon Airways and TripAdvisor. Now in Letsfly Tech, working with travel partners, she mobilize internal and external resources to exploit potential revenue and ensure the successful launch of all the projects.",
            },
            {
              name: 'content1',
              className: 'teams1-content',
              children:
                "Emily is passionate about travel and dedicated to building more value and growth for all parties.",
            },
          ],
        },
        linksWrapper: {
          children: 'https://www.linkedin.com/in/emilyaijunzhu/'
        }
      },
      {
        name: 'block2',
        className: 'block',
        lg: 6, 
        md: 8,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-image',
              children: teamImg3,
            },
            { name: 'title', className: 'teams1-title', children: "Cleo Brow'n" },
            {
              name: 'content',
              className: 'teams1-job',
              children: 'Business Development Director',
            },
            {
              name: 'content1',
              className: 'teams1-content',
              children:
                "Cleo is one of the youngest polished personnel making waves in the Airlines Industry. In the past 2 years, he developed his skill set enabling him to be adaptive and flexible within this growing industry. ",
            },
            {
              name: 'content1',
              className: 'teams1-content',
              children:
                "He built a very reliable network with the World’s top leading Airlines and Service Providers. His resourceful and ingenious skill set increased his department’s portfolio by 30%.",
            },
          ],
        },
        linksWrapper: {
          children: 'https://www.linkedin.com/in/cleo-brow%E2%80%99n-586735249/'
        }
      },
      {
        name: 'block4',
        className: 'block',
        lg: 6, 
        md: 8,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-image',
              children: teamImg5,
            },
            { name: 'title', className: 'teams1-title', children: "David Sun" },
            {
              name: 'content',
              className: 'teams1-job',
              children: 'Director of Airline Partnership',
            },
            {
              name: 'content1',
              className: 'teams1-content',
              children:
                "David has 8 years of experience in travel industry, focusing on develoing a solid commercial and technology relationship with leading airlines and partners. ",
            },
            {
              name: 'content1',
              className: 'teams1-content',
              children:
                "He is skilled in Negotiation, Analytical Skills, Operations Management, Sales, and Marketing and has a proven track record of managing high-value and complex sales lifecycles from the initial requirements stage to contract negotiation and final launch.",
            },
            {
              name: 'content1',
              className: 'teams1-content',
              children:
                "David loves travel industry and is always well-prepared to create more values to travel partners.",
            },
          ],
        },
        linksWrapper: {
          children: 'https://www.linkedin.com/in/david-sun-a694a4145/'
        }
      },
      // {
      //   name: 'block7',
      //   className: 'block',
      //   lg: 6, 
      //   md: 8,
      //   xs: 24,
      //   titleWrapper: {
      //     children: [
      //       {
      //         name: 'image',
      //         className: 'teams1-image',
      //         children: teamImg8,
      //       },
      //       { name: 'title', className: 'teams1-title', children: "Edward Pan" },
      //       {
      //         name: 'content',
      //         className: 'teams1-job',
      //         children: 'Head of Product',
      //       },
      //       {
      //         name: 'content1',
      //         className: 'teams1-content',
      //         children:
      //           "sth",
      //       }
      //     ],
      //   },
      //   linksWrapper: {
      //     children: ''
      //   }
      // },
    ],
  },
  linksWrapper: {
    name: 'links',
    className: 'teams1-links',
    children: [
      {
        name: 'image',
        className: 'linkicon',
        children: linkedin,
      },
    ],
  }
};
export const Teams12DataSource = {
  wrapper: { className: 'home-page-wrapper forms1-wrapper' },
  page: { className: 'home-page forms1' },
  OverPack: { playScale: 0.3, className: '' },
  block: {
    className: 'block-wrapper',
    gutter: 24,
    children: [{
        md: 12,
        xs: 24,
        name: 'first_name',
        itemType: 'inputItem',
        className: 'forms1-item',
        children: 'First name',
        rules: [
          {
            required: true,
            message: 'Please input your first name!',
          }
        ]
      },
      {
        md: 12,
        xs: 24,
        name: 'last_name',
        itemType: 'inputItem',
        className: 'forms1-item',
        children: 'Last name',
        rules: [
          {
            required: true,
            message: 'Please input your last name!',
          }
        ]
      },
      {
        md: 12,
        xs: 24,
        name: 'company_name',
        itemType: 'inputItem',
        className: 'forms1-item',
        children: 'Company Name',
        rules: [
          {
            required: true,
            message: 'Please input your company name!',
          }
        ]
      },
      {
        md: 12,
        xs: 24,
        name: 'country',
        itemType: 'inputItem',
        className: 'forms1-item',
        children: 'Country',
        rules: [
          {
            required: true,
            message: 'Please input your country!',
          }
        ]
      },
      {
        md: 24,
        xs: 24,
        name: 'email',
        itemType: 'inputItem',
        className: 'forms1-item',
        children: 'Email Address',
        rules: [
          {
            required: true,
            message: 'Please input your email address!',
          }
        ]
      },
      {
        md: 24,
        xs: 24,
        name: 'content',
        itemType: 'textAreaItem',
        className: 'forms1-item',
        children: 'Additional Information',
        rules: [
          {
            required: true,
            message: 'Please input some additional information!',
          }
        ]
      },
      {
        md: 12,
        xs: 12,
        name: 'verify_code',
        itemType: 'inputItem',
        className: 'forms1-item',
        children: 'Captcha',
        rules: [
          {
            required: true,
            message: 'Please input your correct captcha!',
          }
        ]
      },
    ],
  },
  childWrapper: {
    className: 'compf7-button-block',
    children: [
      {
        name: 'button',
        className: 'compf7-button-wrapper',
        children: {
          href: '#',
          className: 'compf7-button',
          type: 'primary',
          children: (
            <span>
              <p>Submit</p>
              <i className="buttonIcon"></i>
            </span>
          ),
        },
      }
    ],
  },
};

export const Feature70DataSource = {
  wrapper: { className: 'home-page-wrapper compf7-wrapper' },
  page: { className: 'home-page compf7' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'compf7-title-wrapper',
    children: [
      {
        name: 'title',
        className: 'compf7-title-h1',
        children: 'QUESTIONS & ANSWERS',
      },
    ],
  },
  childWrapper: {
    className: 'compf7-button-block',
    children: [
      {
        name: 'button',
        className: 'compf7-button-wrapper',
        children: {
          href: '/contact',
          className: 'compf7-button',
          type: 'primary',
          children: (
            <span>
              <p>Contact us</p>
              <i className="buttonIcon"></i>
            </span>
          ),
        },
      }
    ],
  },
  blockWrapper: {
    className: 'compf7-block-wrapper',
    gutter: 24,
    children: [
      {
        md: 12,
        xs: 24,
        name: 'block0',
        className: 'compf7-block',
        children: {
          className: 'compf7-block-group',
          children: [
            {
              name: 'question',
              className: 'compf7-block-title',
              children: 'Do you charge L2B fee？',
            },
            {
              name: 'answer',
              className: 'compf7-block-content',
              children: 'No, it is free',
            },
          ],
        },
      },
      {
        md: 12,
        xs: 24,
        name: 'block1',
        className: 'compf7-block',
        children: {
          className: 'compf7-block-group',
          children: [
            {
              name: 'question',
              className: 'compf7-block-title',
              children: 'How much time it will takes for the integration',
            },
            {
              name: 'answer',
              className: 'compf7-block-content',
              children: '2-3 weeks',
            },
          ],
        },
      },
      {
        md: 12,
        xs: 24,
        name: 'block2',
        className: 'compf7-block',
        children: {
          className: 'compf7-block-group',
          children: [
            {
              name: 'question',
              className: 'compf7-block-title',
              children: 'What if your price is not competitive？',
            },
            {
              name: 'answer',
              className: 'compf7-block-content',
              children: 'We can provide fare benchmark comparison reports and credentials to check fares in real time.',
            },
          ],
        },
      },
      {
        md: 12,
        xs: 24,
        name: 'block3',
        className: 'compf7-block',
        children: {
          className: 'compf7-block-group',
          children: [
            {
              name: 'question',
              className: 'compf7-block-title',
              children: 'What if we are not able to support post service',
            },
            {
              name: 'answer',
              className: 'compf7-block-content',
              children: 'Letsfly provide one stop services, can take care of the whole journey for the purchasing.',
            },
          ],
        },
      },
    ],
  },
};