import React from 'react';

import logo from '../Home/assets/logo.png';
import logoWhite from '../Home/assets/logo-w.png';
import linkedin from '../Home/assets/linkedin.jpg';

import iconFarebox from '../Home/assets/icon_farebox.png'
import iconPerformance from '../Home/assets/icon_performance.png'

import topImg from '../Home/assets/top-img.png';
import videoImg from '../Home/assets/img_video.jpg';
import oatImg from '../Home/assets/img_ota.jpg';
import airlinesImg from '../Home/assets/img_airlines.jpg';

import introIcon1 from '../Home/assets/icon1.png';
import introIcon2 from '../Home/assets/icon2.png';
import introIcon3 from '../Home/assets/icon3.png';
import introIcon4 from '../Home/assets/icon4.png';

import brand11 from '../Home/assets/brand11.png';
import brand12 from '../Home/assets/brand12.png';
import brand13 from '../Home/assets/brand13.png';
import brand14 from '../Home/assets/brand14.png';
import brand15 from '../Home/assets/brand15.png';
import brand21 from '../Home/assets/brand21.png';
import brand21a from '../Home/assets/brand21a.png';
import brand22 from '../Home/assets/brand22.png';
import brand23 from '../Home/assets/brand23.png';
import brand23a from '../Home/assets/brand23a.png';
import brand24 from '../Home/assets/brand24.png';
import brand25 from '../Home/assets/brand25.png';
import brand26 from '../Home/assets/brand26.png';
import brand27 from '../Home/assets/brand27.png';
import brand28 from '../Home/assets/brand28.png';
import brand31 from '../Home/assets/brand31.png';
import brand32 from '../Home/assets/brand32.png';
import brand33 from '../Home/assets/brand33.png';
import brand34 from '../Home/assets/brand34.png';
import brand34a from '../Home/assets/brand34a.png';
import brand35 from '../Home/assets/brand35.png';
import brand41 from '../Home/assets/brand41.png';
import brand42 from '../Home/assets/brand42.png';


export const Nav30DataSource = {
  wrapper: { className: 'header3 home-page-wrapper' },
  page: { className: 'home-page' },
  logo: {
    href: '/',
    className: 'header3-logo',
    children: logo,
  },
  Menu: {
    className: 'header3-menu',
    children: [
      {
        name: '/home',
        className: 'header3-item',
        children: {
          href: '/',
          className: '',
          children: [{ children: 'Home', name: 'Home' }],
        }
      },
      {
        name: '/solution',
        className: 'header3-item',
        children: {
          className: '',
          children: [{ children: 'Solution', name: 'Solution' }], 
        },
        subItem: [
          {
            name: '/Product',
            className: 'item-sub',
            children: {
              href: '/product',
              className: 'item-sub-item',
              children: [
                {
                  name: 'image',
                  className: 'item-image',
                  children: iconFarebox,
                },
                {
                  name: 'title',
                  className: 'item-title',
                  children: 'Product',
                },
              ],
            },
          },
          {
            name: '/performance',
            className: 'item-sub',
            children: {
              href: '/performance',
              className: 'item-sub-item',
              children: [
                {
                  name: 'image',
                  className: 'item-image',
                  children: iconPerformance,
                },
                {
                  name: 'title',
                  className: 'item-title',
                  children: 'Performance',
                },
              ],
            },
          },
        ],
      },
      {
        name: '/content',
        className: 'header3-item',
        children: {
          href: '/content',
          className: '',
          children: [{ children: 'Content', name: 'Content' }],
        },
      },
      {
        name: '/company',
        className: 'header3-item',
        children: {
          href: '/company',
          className: '',
          children: [{ children: 'Company', name: 'Company' }],
        },
      },
      {
        name: '/news',
        className: 'header3-item',
        children: {
          href: '/news',
          className: '',
          children: [{ children: 'News', name: 'News' }],
        },
      },
      {
        name: '/contact',
        className: 'header3-item',
        children: {
          href: '/contact',
          className: '',
          children: [{ children: 'Contact', name: 'Contact' }],
        },
      },
    ],
  },
  mobileMenu: { className: 'header3-mobile-menu' },
};
export const Banner50DataSource = {
  wrapper: { className: 'home-page-wrapper banner5' },
  page: { className: 'home-page banner5-page' },
  childWrapper: {
    className: 'banner5-title-wrapper',
    children: [
      { name: 'title', children: 'NO.1 LCC AGGREGATOR IN ASIA', className: 'banner5-title' },
      {
        name: 'content',
        className: 'banner5-content',
        children: 'Inspire OTAs to drive non-GDS profit through next-generation distribution technology and superior services',
      },
      {
        name: 'button',
        className: 'banner5-button-wrapper',
        children: {
          href: '/contact',
          className: 'banner5-button',
          type: 'primary',
          children: (
            <span>
              <p>Get Started</p>
              <i className="buttonIcon"></i>
            </span>
          ),
        },
      },
    ],
  },
  image: {
    className: 'banner5-image',
    children: topImg,
  },
};
export const Banner60DataSource = {
  wrapper: { className: 'home-page-wrapper banner6' },
  page: { className: 'home-page banner6-page' },
  childWrapper: {
    className: 'banner6-title-wrapper',
    children: [
      { name: 'title', children: 'Some titles', className: 'banner6-title' },
      {
        name: 'content',
        className: 'banner6-content',
        children: 'Now, more than ever, the world craves connection. At Atlas, we connect the world via travel technology and worldly expertise.',
      },
      {
        name: 'content',
        className: 'banner6-content',
        children: 'We deeply understand the ecosystem in which our partners and customers operate because we love travel and the people in it. We’re all about bringing people together and going the extra mile to make travel and travel businesses more worthwhile.',
      },
      {
        name: 'image',
        className: 'banner6-image-inner',
        children: videoImg,
      },
      {
        name: 'button',
        className: 'banner6-button-wrapper',
        children: {
          href: '/contact',
          className: 'banner6-button',
          type: 'primary',
          children: (
            <span>
              <p>Learn more</p>
              <i className="buttonIcon"></i>
            </span>
          ),
        },
      },
    ],
  },
  image: {
    className: 'banner6-image',
    children: videoImg,
  },
};
export const Feature50DataSource = {
  wrapper: { className: 'home-page-wrapper feature5-wrapper' },
  page: { className: 'home-page feature5' },
  OverPack: { playScale: 0.3 },
  childWrapper: {
    className: 'feature5-title-wrapper',
    children: [
      { name: 'title', children: 'Why Connect With Letsfly？', className: 'feature5-title' },
      {
        name: 'explain',
        className: 'feature5-explain',
        children: 'Diversify and strengthen your supply chain',
      },
      {
        name: 'content',
        className: 'feature5-content',
        children: 'Letsfly is an innovative distribution platform facilitating OTAs to search, shop, book, track, analyze and service bookings seamlessly with direct connect to LCCs and NDC airlines.',
      },
      {
        name: 'button',
        className: 'feature5-button-wrapper',
        children: {
          href: '/contact',
          className: 'feature5-button',
          type: 'primary',
          children: (
            <span>
              <p>Start Letsfly Now</p>
              <i className="buttonIcon"></i>
            </span>
          ),
        },
      },
    ],
  },
  blockWrapper: {
    className: 'feature5-block-wrapper',
    gutter: 24,
    children: [
      {
        md: 12,
        xs: 24,
        name: 'block',
        className: 'feature5-block',
        children: {
          className: 'feature5-block-group',
          children: [
            {
              name: 'image',
              className: 'feature5-block-image',
              children: introIcon1,
            },
            {
              name: 'title',
              className: 'feature5-block-title',
              children: 'Best Fare Approach ',
            },
            {
              name: 'content',
              className: 'feature5-block-content',
              children: 'Fare-sensitive professionals keep operating and automating the most competitive fares.',
            },
            {
              name: 'content',
              className: 'feature5-block-content',
              children: 'Enable OTA partners a strong fare depth in the competition market.',
            },
          ],
        },
      },
      {
        md: 12,
        xs: 24,
        name: 'block1',
        className: 'feature5-block',
        children: {
          className: 'feature5-block-group',
          children: [
            {
              name: 'image',
              className: 'feature5-block-image',
              children: introIcon2,
            },
            {
              name: 'title',
              className: 'feature5-block-title',
              children: 'Care Your Refund',
            },
            {
              name: 'content',
              className: 'feature5-block-content',
              children: 'Deliver a world-class customer support by 24/7.',
            },
            {
              name: 'content',
              className: 'feature5-block-content',
              children: 'Guarantee service level for each refund and changes of bookings.',
            },
          ],
        },
      },
      {
        md: 12,
        xs: 24,
        name: 'block',
        className: 'feature5-block',
        children: {
          className: 'feature5-block-group',
          children: [
            {
              name: 'image',
              className: 'feature5-block-image',
              children: introIcon3,
            },
            {
              name: 'title',
              className: 'feature5-block-title',
              children: 'Direct Content Coverage',
            },
            {
              name: 'content',
              className: 'feature5-block-content',
              children: 'Commit all direct content is unique from airline with no 3rd party suppliers.',
            },
            {
              name: 'content',
              className: 'feature5-block-content',
              children: 'Full stack support for fare family & ancillary content.',
            },
          ],
        },
      },
      {
        md: 12,
        xs: 24,
        name: 'block',
        className: 'feature5-block',
        children: {
          className: 'feature5-block-group',
          children: [
            {
              name: 'image',
              className: 'feature5-block-image',
              children: introIcon4,
            },
            {
              name: 'title',
              className: 'feature5-block-title',
              children: 'Trustworthy API',
            },
            {
              name: 'content',
              className: 'feature5-block-content',
              children: (
                <span>
                  Faster search response time <span>&#40;&lt;&nbsp;1second&#41;</span>  and greater fare accuracy (99.7%).
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature5-block-content',
              children: 'Strengthen OTAs fundamental ability with no L2B limitation.',
            },
          ],
        },
      },
    ],
  },
};
export const Feature60DataSource = {
  wrapper: { className: 'home-page-wrapper feature6-wrapper' },
  OverPack: { className: 'home-page feature6', playScale: 0.3 },
  Carousel: {
    className: 'feature6-content',
    dots: true,
    wrapper: { className: 'feature6-content-wrapper' },
    titleWrapper: {
      className: 'feature6-title-wrapper',
      barWrapper: {
        className: 'feature6-title-bar-wrapper',
        children: { className: 'feature6-title-bar' },
      },
      title: { className: 'feature6-title' },
    },
    children: [
      {
        title: { className: 'feature6-title-text', children: 'Overview' },
        className: 'feature6-item',
        gutter: 24,
        name: 'block0',
        children: [
          {
            lg: 6,
            md: 12,
            sm: 12,
            xs: 24,
            className: 'feature6-number-wrapper',
            name: 'child0',
            number: {
              className: 'feature6-number',
              unit: { className: 'feature6-unit', children: '+' },
              toText: true,
              children: '40',
            },
            children: { className: 'feature6-text', children: 'LCCs With Direct Connect' },
          },
          {
            lg: 6,
            md: 12,
            sm: 12,
            xs: 24,
            className: 'feature6-number-wrapper',
            name: 'child1',
            number: {
              className: 'feature6-number',
              unit: { className: 'feature6-unit', children: '+' },
              toText: true,
              children: '120',
            },
            children: { className: 'feature6-text', children: 'Airlines  Integrated' },
          },
          {
            lg: 6,
            md: 12,
            sm: 12,
            xs: 24,
            className: 'feature6-number-wrapper',
            name: 'child2',
            number: {
              className: 'feature6-number',
              unit: { className: 'feature6-unit', children: 'M+' },
              toText: true,
              children: '5',
            },
            children: { className: 'feature6-text', children: 'Annual Passengers' },
          },
          {
            lg: 6,
            md: 12,
            sm: 12,
            xs: 24,
            name: 'child0',
            className: 'feature6-number-wrapper',
            number: {
              className: 'feature6-number',
              unit: { className: 'feature6-unit', children: 'M+' },
              toText: true,
              children: '400',
            },
            children: { className: 'feature6-text', children: 'Daily Flight Searches' },
          },
        ],
      },
      {
        title: { className: 'feature6-title-text', children: 'Advanced Technology' },
        className: 'feature6-item',
        gutter: 24,
        name: 'block1',
        children: [
          
          {
            lg: 6,
            md: 12,
            sm: 12,
            xs: 24,
            name: 'child21',
            className: 'feature6-number-wrapper',
            number: {
              className: 'feature6-number',
              unit: { className: 'feature6-unit', children: '-2secs' },
              toText: true,
              children: '"1"',
            },
            children: { className: 'feature6-text', children: 'Average response time' },
          },
          {
            lg: 6,
            md: 12,
            sm: 12,
            xs: 24,
            name: 'child22',
            className: 'feature6-number-wrapper',
            number: {
              className: 'feature6-number',
              unit: { className: 'feature6-unit', children: '%' },
              toText: true,
              children: '99.7',
            },
            children: { className: 'feature6-text', children: 'Booking Success Rate' },
          },
          {
            lg: 6,
            md: 12,
            sm: 12,
            xs: 24,
            name: 'child23',
            className: 'feature6-number-wrapper',
            number: {
              className: 'feature6-number',
              unit: { className: 'feature6-unit', children: '+' },
              toText: true,
              children: '100',
            },
            children: { className: 'feature6-text', children: '24/7 Post-ticketing Service Staff' },
          },
          {
            lg: 6,
            md: 12,
            sm: 12,
            xs: 24,
            name: 'child24',
            className: 'feature6-number-wrapper',
            number: {
              className: 'feature6-number',
              unit: { className: 'feature6-unit', children: '+' },
              toText: true,
              children: '100',
            },
            children: { className: 'feature6-text', children: 'Technical Staff' },
          },
        ],
      }
    ],
  },
};
export const Feature70DataSource = {
  wrapper: { className: 'home-page-wrapper feature7-wrapper' },
  page: { className: 'home-page feature7' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'feature7-title-wrapper',
    children: [
      {
        name: 'title',
        className: 'feature7-title-h1',
        children: 'FOR OTA & AIRLINES',
      },
      {
        name: 'bottomline',
        className: 'feature7-title-bottomline',
        children: 'bottomline',
      },
    ],
  },
  blockWrapper: {
    className: 'feature7-block-wrapper',
    gutter: 24,
    children: [
      {
        md: 12,
        xs: 24,
        name: 'block',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image',
              children: oatImg,
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: 'For OTAs',
            },
            {
              name: 'content',
              className: 'feature7-block-content feature7-block-otas',
              children: 'Direct access to airline content, no 3rd parties.',
            },
            {
              name: 'content',
              className: 'feature7-block-content feature7-block-otas',
              children: 'Best fare approach, flexible and reliable.',
            },
            {
              name: 'content',
              className: 'feature7-block-content feature7-block-otas',
              children: 'Care / process for each refund, post service.',
            },
            {
              name: 'content',
              className: 'feature7-block-content feature7-block-otas',
              children: 'Faster search response time (<1second)  with fare accuracy(99.7%).',
            },
          ],
        },
      },
      {
        md: 12,
        xs: 24,
        name: 'block1',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image',
              children: airlinesImg,
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: 'For Airlines',
            },
            {
              name: 'content',
              className: 'feature7-block-content feature7-block-airlines',
              children: 'Diversify distribution channels in simplicity.',
            },
            {
              name: 'content',
              className: 'feature7-block-content feature7-block-airlines',
              children: 'High-scale support for fare family & ancillary.',
            },
            {
              name: 'content',
              className: 'feature7-block-content feature7-block-airlines',
              children: 'Fulfillment with post-service booking and refund.',
            },
            {
              name: 'content',
              className: 'feature7-block-content feature7-block-airlines',
              children: 'Ensure quality and speed by 10+ years professionals.',
            }
          ],
        },
      },
    ],
  },
};
export const BrandPartnership = {
  wrapper: { className: 'home-page-wrapper brand1-wrapper' },
  page: { className: 'home-page brand1' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'brand1-title-wrapper',
    children: [
      {
        name: 'title',
        className: 'brand1-title-h1',
        children: 'KEY PARTNERSHIPS',
      },
      {
        name: 'bottomline',
        className: 'brand1-title-bottomline',
        children: 'bottomline',
      },
      {
        name: 'content',
        className: 'brand1-title-content',
        children: 'Our diverse group of partners are the driving force behind the success and satisfaction of our clients around the world',
      },
    ],
  },
  blockWrapper: {
    className: 'brand1-block-wrapper',
    gutter: 24,
    justify: 'center',
    children: [
      {
        name: 'brand1',
        className: 'brand1-block',
        xs: 24,
        md: 24,
        children: {
          className: 'brand1-block-group',
          children: [
            {
              name: 'image',
              className: 'brand1-block-image',
              children: brand11,
            },
            {
              name: 'image',
              className: 'brand1-block-image',
              children: brand12,
            },
            {
              name: 'image',
              className: 'brand1-block-image',
              children: brand13,
            },
            {
              name: 'image',
              className: 'brand1-block-image',
              children: brand14,
            },
            {
              name: 'image',
              className: 'brand1-block-image',
              children: brand15,
            },
          ],
        },
      },
      {
        name: 'brand2',
        className: 'brand1-block',
        xs: 8,
        md: 4,
        children: {
          className: 'brand1-block-group',
          children: [
            {
              name: 'image',
              className: 'brand1-block-image',
              children: brand21,
            },
            {
              name: 'image',
              className: 'brand1-block-image',
              children: brand21a,
            },
            {
              name: 'image',
              className: 'brand1-block-image',
              children: brand23,
            },
            {
              name: 'image',
              className: 'brand1-block-image',
              children: brand23a,
            },
            {
              name: 'image',
              className: 'brand1-block-image',
              children: brand24,
            },
            {
              name: 'image',
              className: 'brand1-block-image',
              children: brand25,
            },
            {
              name: 'image',
              className: 'brand1-block-image',
              children: brand26,
            },
            {
              name: 'image',
              className: 'brand1-block-image',
              children: brand27,
            },
            {
              name: 'image',
              className: 'brand1-block-image',
              children: brand28,
            },
          ],
        },
      },
      {
        name: 'brand3',
        className: 'brand1-block',
        children: {
          xs: 8,
          md: 4,
          className: 'brand1-block-group',
          children: [
            {
              name: 'image',
              className: 'brand1-block-image',
              children: brand31,
            },
            {
              name: 'image',
              className: 'brand1-block-image',
              children: brand32,
            },
            {
              name: 'image',
              className: 'brand1-block-image',
              children: brand33,
            },
            {
              name: 'image',
              className: 'brand1-block-image',
              children: brand34,
            },
            {
              name: 'image',
              className: 'brand1-block-image',
              children: brand34a,
            }
          ],
        },
      },
      {
        name: 'brand4',
        className: 'brand1-block',
        children: {
          xs: 8,
          md: 4,
          className: 'brand1-block-group',
          children: [
            {
              name: 'image',
              className: 'brand1-block-image',
              children: brand35
            },
            // {
            //   name: 'image',
            //   className: 'brand1-block-image',
            //   children: brand41,
            // },
            {
              name: 'image',
              className: 'brand1-block-image',
              children: brand42,
            },
          ],
        },
      },
    ],
  },
};
export const Footer10DataSource = {
  wrapper: { className: 'home-page-wrapper footer1-wrapper' },
  OverPack: { className: 'footer1', playScale: 0.2 },
  block: {
    className: 'home-page',
    gutter: 0,
    children: [
      {
        name: 'block0',
        xs: 24,
        md: 12,
        className: 'block',
        title: {
          name: 'logo-image',
          className: 'logo',
          children: logoWhite,
        },
        childWrapper: {
          name:'email',
          className: 'slogan',
          children: [
            {
              name: 'content0',
              children: 'sales@letsflytech.com',
            },
          ],
        },
        linksWrapper: {
          className: 'links',
          children: [
            {
              name: 'image',
              href: "https://www.linkedin.com/company/13178110/admin/",
              className: 'linkicon',
              children: linkedin,
            },
          ],
        },
      },
      {
        name: 'block1',
        xs: 24,
        md: 4,
        className: 'block',
        title: { children: 'SOLUTIONS' },
        childWrapper: {
          children: [
            { name: 'link0', href: '/product', children: 'Product' },
            { name: 'link1', href: '/content', children: 'Content' },
          ],
        },
        linksWrapper: {}
      },
      {
        name: 'block2',
        xs: 24,
        md: 4,
        className: 'block',
        title: { children: 'WHO WE ARE' },
        childWrapper: {
          children: [
            { href: '/company', name: 'link0', children: 'Company' },
            { href: '/news', name: 'link1', children: 'News' },
          ],
        },
        linksWrapper: {}
      },
      {
        name: 'block3',
        xs: 24,
        md: 4,
        className: 'block',
        title: { children: 'STAY IN TOUCH' },
        childWrapper: {
          children: [
            { href: '/contact', name: 'link0', children: 'Contact us' },
            { href: '/content', name: 'link1', children: 'Get started' },
          ],
        },
        linksWrapper: {}
      },
    ],
  },
  copyrightWrapper: { className: 'copyright-wrapper' },
  copyrightPage: { className: 'home-page' },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        © 2013-2023 <a href="https://www.letsflytech.com/">Letsfly</a>. All rights reserved. 
      </span>
    ),
  },
};
