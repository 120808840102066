import React from 'react';

import topImg from '../Farebox/assets/top_img.png';
import guaranteeImg from '../Farebox/assets/guarantee.png';

import serviceImg1 from '../Farebox/assets/service_img1.png';
import serviceImg2 from '../Farebox/assets/service_img2.png';
import serviceImg3 from '../Farebox/assets/service_img3.png';

import detailIcon1 from '../Farebox/assets/d_icon1.png';
import detailIcon2 from '../Farebox/assets/d_icon2.png';
import detailIcon3 from '../Farebox/assets/d_icon3.png';
import detailIcon4 from '../Farebox/assets/d_icon4.png';
import detailIcon5 from '../Farebox/assets/d_icon5.png';
import detailIcon6 from '../Farebox/assets/d_icon6.png';
import detailIcon7 from '../Farebox/assets/d_icon7.png';
import detailIcon8 from '../Farebox/assets/d_icon8.png';

import fbbr11 from '../Farebox/assets/fbbr11.png';
import fbbr12 from '../Farebox/assets/fbbr12.png';
import fbbr13 from '../Farebox/assets/fbbr13.png';
import fbbr14 from '../Farebox/assets/fbbr14.png';
import fbbr15 from '../Farebox/assets/fbbr15.png';
import fbbr16 from '../Farebox/assets/fbbr16.png';
import fbbr21 from '../Farebox/assets/fbbr21.png';
import fbbr22 from '../Farebox/assets/fbbr22.png';
import fbbr23 from '../Farebox/assets/fbbr23.png';
import fbbr24 from '../Farebox/assets/fbbr24.png';
import fbbr25 from '../Farebox/assets/fbbr25.png';

export const Banner50DataSource = {
  wrapper: { className: 'home-page-wrapper fbb5 fbb5-wrapper' },
  page: { className: 'home-page fbb5-page' },
  childWrapper: {
    className: 'fbb5-title-wrapper',
    children: [
      { name: 'title', children: 'MAKE IT DIFFERENT', className: 'fbb5-title' },
      {
        name: 'bottomline',
        className: 'fbb5-bottomline',
        children: 'bottomline',
      },
      {
        name: 'content',
        className: 'fbb5-content',
        children: (<div><strong>Letsfly</strong> is a one-stop distribution platform to empower OTAs directly approach competitive flight fares, with a full-scale of operation and service support.</div>),
      }
    ],
  },
  image: {
    className: 'fbb5-image',
    children: topImg,
  },
};
export const Banner60DataSource = {
  wrapper: { className: 'home-page-wrapper fbb6' },
  page: { className: 'home-page fbb6-page' },
  childWrapper: {
    className: 'fbb6-title-wrapper',
    children: [
      { name: 'title', children: 'BEST FARE APPROACH', className: 'fbb6-title' },
      {
        name: 'explain',
        className: 'fbb6-explain',
        children: 'What make us different',
      },
      {
        name: 'selection',
        className: 'fbb6-selection',
        children: (
          <div className="selection-innner">Direct Connect</div>
        ),
      },
      {
        name: 'selection',
        className: 'fbb6-selection',
        children: (
          <div className="selection-innner">Highly Controlled Content</div>
        )
      },
      {
        name: 'selection',
        className: 'fbb6-selection',
        children: (
          <div className="selection-innner">Automated calculation of rebate policies and limited time offers</div>
        )
      },
      {
        name: 'content',
        className: 'fbb6-content',
        children: 'Equipped with excellent technical capabilities and professional revenue optimization experience, we are capable of capturing and integrating all the available air fares, discounts and rebate policies in real time, and to fine-tune delicately to deliver the most competitive fares and fare bundles to travel partners.',
      },
      {
        name: 'image',
        className: 'fbb6-image-inner',
        children: guaranteeImg,
      },
    ],
  },
  image: {
    className: 'fbb6-image',
    children: guaranteeImg,
  },
};
export const Chart60DataSource = {
  wrapper: { className: 'home-page-wrapper cht6' },
  page: { className: 'home-page cht6-page' },
  childWrapper: {
    className: 'cht6-title-wrapper',
    children: [
      { name: 'title', children: 'Ability to handle 400+ million queries on daily basis.', className: 'cht6-title' },
      {
        name: 'explain',
        className: 'cht6-explain',
        children: 'Daily Queries (by million, GMT + 8:00)',
      }
    ],
  },
  config: {
    yAxisName: 'Daily Queries (by million)',
    dataUnit: 'M',
    grid: {
      x: 70,
      x2: 70,
      y: 90,
      y2: 90
    }
  }
};
export const Chart61DataSource = {
  wrapper: { className: 'home-page-wrapper cht6' },
  page: { className: 'home-page cht6-page' },
  childWrapper: {
    className: 'cht6-title-wrapper',
    children: [
      { name: 'title', children: 'How fast the fare-query response, is a fundamental ability to track.', className: 'cht6-title' },
      {
        name: 'explain',
        className: 'cht6-explain',
        children: 'Average Response Time (GMT +8:00)',
      }
    ],
  },
  config: {
    yAxisName: 'Avg Rs Time (Seconds)',
    dataUnit: 's',
    grid: {
      x: 60,
      x2: 60,
      y: 80,
      y2: 80
    }
  }
};
export const Chart62DataSource = {
  wrapper: { className: 'home-page-wrapper cht6' },
  page: { className: 'home-page cht6-page' },
  childWrapper: {
    className: 'cht6-title-wrapper',
    children: [
      { name: 'title', children: 'Balancing the high-response time, we secure the query accuracy at a high level.', className: 'cht6-title' },
      {
        name: 'explain',
        className: 'cht6-explain',
        children: 'Successful rate: Fare validation (GMT +8:00)',
      }
    ],
  },
  config: {
    yAxisName: 'Fare validation successful rate',
    dataUnit: '%',
    grid: {
      x: 60,
      x2: 60,
      y: 80,
      y2: 80
    }
  }
};
export const Feature50DataSource = {
  wrapper: { className: 'home-page-wrapper fbf5-wrapper' },
  page: { className: 'home-page fbf5' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'fbf5-title-wrapper',
    children: [
      {
        name: 'title',
        className: 'fbf5-title-h1',
        children: 'DETAILS MAKE SUCCESS',
      },
      {
        name: 'bottomline',
        className: 'fbf5-title-bottomline',
        children: 'bottomline',
      },
    ],
  },
  blockWrapper: {
    className: 'fbf5-block-wrapper',
    gutter: 48,
    children: [
      {
        md: 12,
        xs: 24,
        name: 'block1',
        className: 'fbf5-block',
        children: {
          className: 'fbf5-block-group',
          children: [
            {
              name: 'image',
              className: 'fbf5-block-image',
              children: detailIcon1,
            },
            {
              name: 'title',
              className: 'fbf5-block-title',
              children: 'Maximized Coverage',
            },
            {
              name: 'content',
              className: 'fbf5-block-content',
              children: 'We connect with over 124 airlines in depth and creativity, including 40+LCCs via direct connect, and NDCs and non NDCs, to enrich the air fares and products we deliver.',
            }
          ],
        },
      },
      {
        md: 12,
        xs: 24,
        name: 'block2',
        className: 'fbf5-block',
        children: {
          className: 'fbf5-block-group',
          children: [
            {
              name: 'image',
              className: 'fbf5-block-image',
              children: detailIcon2,
            },
            {
              name: 'title',
              className: 'fbf5-block-title',
              children: 'Best Price via Direct Connect',
            },
            {
              name: 'content',
              className: 'fbf5-block-content',
              children: 'Direct connect with multiple airlines to get first hand content dynamically, which is highly controllable and customized to ensure its competitiveness and availability.',
            },
          ],
        },
      },
      {
        md: 12,
        xs: 24,
        name: 'block3',
        className: 'fbf5-block',
        children: {
          className: 'fbf5-block-group',
          children: [
            {
              name: 'image',
              className: 'fbf5-block-image',
              children: detailIcon3,
            },
            {
              name: 'title',
              className: 'fbf5-block-title',
              children: 'Ancillary Support',
            },
            {
              name: 'content',
              className: 'fbf5-block-content',
              children: 'We support multiple ancillaries, including but not limit to baggage, check in, seat selection etc.',
            },
            {
              name: 'content',
              className: 'fbf5-block-content',
              children: 'We keep expanding other value-added ancillaries, to enhance customers’ one-stop shopping experience.',
            },
          ],
        },
      },
      {
        md: 12,
        xs: 24,
        name: 'block4',
        className: 'fbf5-block',
        children: {
          className: 'fbf5-block-group',
          children: [
            {
              name: 'image',
              className: 'fbf5-block-image',
              children: detailIcon4,
            },
            {
              name: 'title',
              className: 'fbf5-block-title',
              children: 'Rebate &Promotions',
            },
            {
              name: 'content',
              className: 'fbf5-block-content',
              children: 'We constantly looking for the best pricing combination, supported by a comprehensive evaluation of the rebate policy and time-limited offers, to help our partners achieve great pricing advantages in the market.',
            },
          ],
        },
      },
      {
        md: 12,
        xs: 24,
        name: 'block5',
        className: 'fbf5-block',
        children: {
          className: 'fbf5-block-group',
          children: [
            {
              name: 'image',
              className: 'fbf5-block-image',
              children: detailIcon5,
            },
            {
              name: 'title',
              className: 'fbf5-block-title',
              children: 'Streamlined Integration',
            },
            {
              name: 'content',
              className: 'fbf5-block-content',
              children: 'Our reliable and easy-to-customized API interface realizes seamless connection with intuitive operations, you can access massive air content via simple integration.',
            },
          ],
        },
      },
      {
        md: 12,
        xs: 24,
        name: 'block6',
        className: 'fbf5-block',
        children: {
          className: 'fbf5-block-group',
          children: [
            {
              name: 'image',
              className: 'fbf5-block-image',
              children: detailIcon6,
            },
            {
              name: 'title',
              className: 'fbf5-block-title',
              children: 'Visualized Platform',
            },
            {
              name: 'content',
              className: 'fbf5-block-content',
              children: 'With a visualized management platform, you can easily finalize and manage the entire booking process from search to ticketing and post-ticketing.',
            },
          ],
        },
      },
      {
        md: 12,
        xs: 24,
        name: 'block7',
        className: 'fbf5-block',
        children: {
          className: 'fbf5-block-group',
          children: [
            {
              name: 'image',
              className: 'fbf5-block-image',
              children: detailIcon7,
            },
            {
              name: 'title',
              className: 'fbf5-block-title',
              children: 'Leading and Stable API',
            },
            {
              name: 'content',
              className: 'fbf5-block-content',
              children: 'High quality data and real time adjustment ensure the pricing and availability during the whole ticketing process.',
            },
          ],
        },
      },
      {
        md: 12,
        xs: 24,
        name: 'block8',
        className: 'fbf5-block',
        children: {
          className: 'fbf5-block-group',
          children: [
            {
              name: 'image',
              className: 'fbf5-block-image',
              children: detailIcon8,
            },
            {
              name: 'title',
              className: 'fbf5-block-title',
              children: 'Automated Post-Ticketing Support',
            },
            {
              name: 'content',
              className: 'fbf5-block-content',
              children: 'We take care your Post-Ticketing requirements via automated self-submit, together with a fast response and one-on-one manual service support subject to your customized requirement.',
            },
          ],
        },
      },
    ],
  },
};
export const Feature60DataSource = {
  wrapper: { className: 'home-page-wrapper fbf6-wrapper' },
  page: { className: 'home-page fbf6' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'fbf6-title-wrapper',
    children: [
      {
        name: 'title',
        className: 'fbf6-title-h1',
        children: 'HOW WE DIFFERENT FROM OTHERS',
      },
      {
        name: 'bottomline',
        className: 'fbf6-title-bottomline',
        children: 'bottomline',
      },
    ],
  },
  blockWrapper: {
    className: 'fbf6-block-wrapper',
    gutter: 24,
    children: [
      {
        lg: 8,
        md: 12,
        xs: 24,
        name: 'block',
        className: 'fbf6-block block-left',
        children: {
          className: 'fbf6-block-group',
          children: [
            {
              name: 'banner',
              className: 'fbf6-block-banner fbf6-banner-airline',
              children: 'airline',
            },
            {
              name: 'title',
              className: 'fbf6-block-title',
              children: 'Flight Aggregators',
            },
            {
              name: 'explain',
              className: 'fbf6-block-explain',
              children: 'Perform as a software',
            },
            {
              name: 'content',
              className: 'fbf6-block-content fbf6-block-normal',
              children: 'Direct connects',
            },
            {
              name: 'content',
              className: 'fbf6-block-content fbf6-block-normal',
              children: 'Ancillary support',
            },
            {
              name: 'content',
              className: 'fbf6-block-content fbf6-block-triangle',
              children: 'Limited booking change notify',
            },
            {
              name: 'content',
              className: 'fbf6-block-content fbf6-block-triangle',
              children: 'Limited refund/change support',
            },
            {
              name: 'content',
              className: 'fbf6-block-content fbf6-block-triangle',
              children: 'L2B restrictions',
            },
            {
              name: 'content',
              className: 'fbf6-block-content fbf6-block-normal',
              children: 'Reporting / Management system',
            },
            {
              name: 'content',
              className: 'fbf6-block-content fbf6-block-normal',
              children: 'Credit card / Cash',
            },
            {
              name: 'content',
              className: 'fbf6-block-content fbf6-block-normal',
              children: 'Multi-currency',
            },
          ],
        },
      },
      {
        lg: 8,
        md: 24,
        xs: 24,
        name: 'block1',
        className: 'fbf6-block block-center',
        children: {
          className: 'fbf6-block-group',
          children: [
            {
              name: 'banner',
              className: 'fbf6-banner-wrapper fbf6-banner-recommend',
              children: 'Recommend',
            },
            {
              name: 'title',
              className: 'fbf6-block-title',
              children: 'LETSFLY',
            },
            {
              name: 'explain',
              className: 'fbf6-block-explain',
              children: 'Perform as a solution with services',
            },
            {
              name: 'content',
              className: 'fbf6-block-content fbf6-block-active',
              children: 'Direct connect',
            },
            {
              name: 'content',
              className: 'fbf6-block-content fbf6-block-active',
              children: 'Hidden fares',
            },
            {
              name: 'content',
              className: 'fbf6-block-content fbf6-block-active',
              children: 'Ancillary support',
            },
            {
              name: 'content',
              className: 'fbf6-block-content fbf6-block-active',
              children: 'Booking change notify',
            },
            {
              name: 'content',
              className: 'fbf6-block-content fbf6-block-active',
              children: 'Refund/change support',
            },
            {
              name: 'content',
              className: 'fbf6-block-content fbf6-block-active',
              children: 'No L2B cost',
            },
            {
              name: 'content',
              className: 'fbf6-block-content fbf6-block-active',
              children: 'Reporting/Management system',
            },
            {
              name: 'content',
              className: 'fbf6-block-content fbf6-block-active',
              children: 'Credit card / cash',
            },
            {
              name: 'content',
              className: 'fbf6-block-content fbf6-block-active',
              children: 'Multi-currency',
            },
            {
              name: 'button',
              className: 'fbf6-button-wrapper',
              children: {
                href: '/contact',
                className: 'fbf6-button',
                type: 'primary',
                children: (
                  <span>
                    <p>Get Started</p>
                    <i className="buttonIcon"></i>
                  </span>
                ),
              },
            },
          ],
        },
      },
      {
        lg: 8,
        md: 12,
        xs: 24,
        name: 'block2',
        className: 'fbf6-block block-right',
        children: {
          className: 'fbf6-block-group',
          children: [
            {
              name: 'banner',
              className: 'fbf6-block-banner fbf6-banner-content',
              children: 'content',
            },
            {
              name: 'title',
              className: 'fbf6-block-title',
              children: 'Airline content Consolidators',
            },
            {
              name: 'explain',
              className: 'fbf6-block-explain',
              children: 'Perform as a marketplace',
            },
            {
              name: 'content',
              className: 'fbf6-block-content fbf6-block-triangle',
              children: 'Numerous 3rd party providers',
            },
            {
              name: 'content',
              className: 'fbf6-block-content fbf6-block-triangle',
              children: 'Hidden fares',
            },
            {
              name: 'content',
              className: 'fbf6-block-content fbf6-block-triangle',
              children: 'Limited ancillary support',
            },
            {
              name: 'content',
              className: 'fbf6-block-content fbf6-block-triangle',
              children: 'Limited booking change notify',
            },
            {
              name: 'content',
              className: 'fbf6-block-content fbf6-block-triangle',
              children: 'Limited refund / change support',
            },
            {
              name: 'content',
              className: 'fbf6-block-content fbf6-block-normal',
              children: 'Reporting / Management system',
            },
            {
              name: 'content',
              className: 'fbf6-block-content fbf6-block-normal',
              children: 'Credit card / Cash',
            },
            {
              name: 'content',
              className: 'fbf6-block-content fbf6-block-normal',
              children: 'Multi-currency',
            },
          ],
        },
      },
    ],
  },
};
export const Feature70DataSource = {
  wrapper: { className: 'home-page-wrapper fbf7-wrapper' },
  page: { className: 'home-page fbf7' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'fbf7-title-wrapper',
    children: [
      {
        name: 'title',
        className: 'fbf7-title-h1',
        children: 'CARE YOUR REFUND',
      },
      {
        name: 'explain',
        className: 'fbf7-title-explain',
        children: 'What make us different',
      },
    ],
  },
  blockWrapper: {
    className: 'fbf7-block-wrapper',
    gutter: 24,
    children: [
      {
        md: 8,
        xs: 24,
        name: 'block',
        className: 'fbf7-block',
        children: {
          className: 'fbf7-block-group',
          children: [
            {
              name: 'image',
              className: 'fbf7-block-image',
              children: serviceImg1,
            },
            {
              name: 'title',
              className: 'fbf7-block-title',
              children: '7/24',
            },
            {
              name: 'content',
              className: 'fbf7-block-content',
              children: 'World-class Post-ticketing support team respond within 2 hours 24/7',
            }
          ],
        },
      },
      {
        md: 8,
        xs: 24,
        name: 'block1',
        className: 'fbf7-block',
        children: {
          className: 'fbf7-block-group',
          children: [
            {
              name: 'image',
              className: 'fbf7-block-image',
              children: serviceImg2,
            },
            {
              name: 'title',
              className: 'fbf7-block-title',
              children: 'Instant',
            },
            {
              name: 'content',
              className: 'fbf7-block-content',
              children: '4-5 times post-ticketing validation to guarantee any flight, change/cancellation notified to passengers instantly',
            },
          ],
        },
      },
      {
        md: 8,
        xs: 24,
        name: 'block2',
        className: 'fbf7-block',
        children: {
          className: 'fbf7-block-group',
          children: [
            {
              name: 'image',
              className: 'fbf7-block-image',
              children: serviceImg3,
            },
            {
              name: 'title',
              className: 'fbf7-block-title',
              children: 'Over 95%',
            },
            {
              name: 'content',
              className: 'fbf7-block-content',
              children: '5 step refund process with tracking on a daily basis, assures the refund success rate >95%',
            },
          ],
        },
      },
    ],
  },
};
export const BrandExtensive = {
  wrapper: { className: 'home-page-wrapper fbbr1-wrapper' },
  page: { className: 'home-page fbbr1' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'fbbr1-title-wrapper',
    children: [
      {
        name: 'title',
        className: 'fbbr1-title-h1',
        children: 'EXTENSIVE DIRECT CONTENT',
      },
      {
        name: 'bottomline',
        className: 'fbbr1-title-bottomline',
        children: 'bottomline',
      },
      {
        name: 'content',
        className: 'fbbr1-title-content',
        children: 'Covering Top 1 ~ 3 low-cost-carriers from each country in Asia and Oceania major LCC and FSC carriers in Europe and Americas.',
      },
    ],
  },
  childWrapper: {
    className: 'fbbr1-button-block',
    children: [
      {
        name: 'button',
        className: 'fbbr1-button-wrapper',
        children: {
          href: '/content',
          className: 'fbbr1-button',
          type: 'primary',
          children: (
            <span>
              <p>Read More</p>
              <i className="buttonIcon"></i>
            </span>
          ),
        },
      }
    ],
  },
  blockWrapper: {
    className: 'fbbr1-block-wrapper',
    gutter: 24,
    justify: 'center',
    children: [
      {
        name: 'fbbr1',
        className: 'fbbr1-block',
        xs: 24,
        md: 24,
        children: {
          className: 'fbbr1-block-group',
          children: [
            {
              name: 'image',
              className: 'fbbr1-block-image',
              children: fbbr11,
            },
            {
              name: 'image',
              className: 'fbbr1-block-image',
              children: fbbr12,
            },
            {
              name: 'image',
              className: 'fbbr1-block-image',
              children: fbbr13,
            },
            {
              name: 'image',
              className: 'fbbr1-block-image',
              children: fbbr14,
            },
            {
              name: 'image',
              className: 'fbbr1-block-image',
              children: fbbr15,
            },
            {
              name: 'image',
              className: 'fbbr1-block-image',
              children: fbbr16,
            },
          ],
        },
      },
      {
        name: 'brand2',
        className: 'fbbr1-block',
        xs: 8,
        md: 4,
        children: {
          className: 'fbbr1-block-group',
          children: [
            {
              name: 'image',
              className: 'fbbr1-block-image',
              children: fbbr21,
            },
            {
              name: 'image',
              className: 'fbbr1-block-image',
              children: fbbr22,
            },
            {
              name: 'image',
              className: 'fbbr1-block-image',
              children: fbbr23,
            },
            {
              name: 'image',
              className: 'fbbr1-block-image',
              children: fbbr24,
            },
            {
              name: 'image',
              className: 'fbbr1-block-image',
              children: fbbr25,
            },
          ],
        },
      },
    ],
  },
};
