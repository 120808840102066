import React from 'react';
import QueueAnim from 'rc-queue-anim';
import { Row, Col, Modal } from 'antd';
// import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import { getChildrenToRender } from '../utils/index';

class Teams1 extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      modalSate: false,
      dialogData: {
        img: {},
        content: [],
        link: {}
      }
    }
  }
  showModal = (i, item) => {
    const [first, ...other] = item.titleWrapper.children || []
    const link = item.linksWrapper || {}
    this.setState({
      modalSate: true,
      dialogData: {
        img: first,
        content: other,
        link
      }
    })
  };
  handleOk = () => {
    this.setState({
      modalSate: false
    })
  };
  handleCancel = () => {
    this.setState({
      modalSate: false
    })
  };
  getBlockChildren = (data) =>
    data.map((item, i) => {
      const { titleWrapper, linksWrapper, ...$item } = item;
      return (
        <Col key={i.toString()} {...$item}>
          <div className="block-inner">
            {titleWrapper.children.map(getChildrenToRender)}
            <div className="block-addon">
                <span className="teams1-readmore" onClick={() => {
                  this.showModal(i, item)
                }}>Read BIO</span>
            </div>
          </div>
        </Col>
      );
    });

  render() {
    const { ...props } = this.props;
    const { dataSource } = props;
    delete props.dataSource;
    delete props.isMobile;
    const listChildren = this.getBlockChildren(dataSource.block.children);
    const linksWrapper = dataSource.linksWrapper;
    return (
      <div {...props} {...dataSource.wrapper}>
        <div {...dataSource.page}>
          <div {...dataSource.titleWrapper}>
            {dataSource.titleWrapper.children.map(getChildrenToRender)}
            <div className="title-bottomline"></div>
          </div>
          <div {...dataSource.OverPack}>
            <QueueAnim
              type="bottom"
              key="block"
              leaveReverse
              {...dataSource.block}
              component={Row}
            >
              {listChildren}
            </QueueAnim>
          </div>
        </div>
        <Modal
          open={this.state.modalSate}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          maskStyle={{'background': 'rgba(0, 0, 0, 0.5)'}}
          width={960}
          footer= {null}
        >
          <div className="teams1-dialog">
            <div className="teams1-dialog-page">
              <div className="teams1-dialog-image">
                <img src={this.state.dialogData.img.children} width="100%" alt="img" />
              </div>
              <div className="teams1-dialog-title-wrapper">
                {this.state.dialogData.content.map(getChildrenToRender)}
                <div className="teams1-links">
                  <a href={this.state.dialogData.link.children} target="_blank" rel="noopener noreferrer">
                    {linksWrapper.children.map(getChildrenToRender)}
                  </a>
                </div>  
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default Teams1;
