import React from 'react';
import TweenOne from 'rc-tween-one';
import { Menu, Button } from 'antd';
import { getChildrenToRender } from '../utils/index';
import { withRouter } from '../utils/index';

const { Item, SubMenu } = Menu;
class Header3 extends React.Component {
  constructor(props) {
    super(props);
    const key = props.router.location.pathname
    this.state = {
      phoneOpen: undefined,
      locationKey: key === '/' ? '/home' : key
    };
  }

  phoneClick = () => {
    const phoneOpen = !this.state.phoneOpen;
    this.setState({
      phoneOpen,
    });
  };

  render() {
    const { dataSource, isMobile, ...props } = this.props;
    // 优化 isMobile
    const { phoneOpen } = this.state;
    let deviceType = props.router.windowSize.width < 1200
    const navData = dataSource.Menu.children;
    const navChildren = navData.map((item) => {
      const { children: a, subItem, ...itemProps } = item;
      if (subItem) {
        return (
          <SubMenu
            key={item.name}
            {...itemProps}
            title={
              <div
                {...a}
                className={`header3-item-block ${a.className}`.trim()}
              >
                {a.children.map(getChildrenToRender)}
              </div>
            }
            popupClassName="header3-item-child"
          >
            {subItem.map(($item, ii) => {
              const { children: childItem } = $item;
              const child = childItem.href ? (
                <a {...childItem}>
                  {childItem.children.map(getChildrenToRender)}
                </a>
              ) : (
                <div {...childItem}>
                  {childItem.children.map(getChildrenToRender)}
                </div>
              );
              return (
                <Item key={$item.name || ii.toString()} {...$item}>
                  {child}
                </Item>
              );
            })}
          </SubMenu>
        );
      }
      return (
        <Item key={item.name} {...itemProps}>
          <a {...a} className={`header3-item-block ${a.className}`.trim()}>
            {a.children.map(getChildrenToRender)}
          </a>
        </Item>
      );
    });
    const moment = phoneOpen === undefined ? 300 : null;
    const onHandleClickRegister = () => {
      var link = `/contact`;
      window.location.assign(link);
    };
    const onHandleClickLogin = () => {
      var link = `http://partner.letsflytech.com/index/user/index.html`;
      window.location.assign(link);
    };
    return (
      <TweenOne
        component="header"
        animation={{ opacity: 0, type: 'from' }}
        {...dataSource.wrapper}
        {...props}
      >
        <div
          {...dataSource.page}
          className={`${dataSource.page.className}${phoneOpen ? ' open' : ''}`}
        >
          <TweenOne
            animation={{ x: -30, type: 'from', ease: 'easeOutQuad' }}
            {...dataSource.logo}
          >
            <a href={dataSource.logo.href}><img width="100%" src={dataSource.logo.children} alt="img" /></a>
          </TweenOne>
          <TweenOne
            {...dataSource.Menu}
            animation={
              isMobile
                ? {
                    x: 0,
                    height: 0,
                    duration: 300,
                    onComplete: (e) => {
                      if (this.state.phoneOpen) {
                        e.target.style.height = 'auto';
                      }
                    },
                    ease: 'easeInOutQuad',
                  }
                : null
            }
            moment={moment}
            reverse={!!phoneOpen}
          >
            <Menu
              mode={deviceType ? 'inline' : 'horizontal'}
              defaultSelectedKeys={['/home']}
              selectedKeys={[this.state.locationKey]}
              theme="light"
            >
              {navChildren}
            </Menu>
          </TweenOne>
          <div className="header-button">
            <Button type="primary" className="register" onClick={onHandleClickRegister}>
              Register
            </Button>
            <Button type="primary" className="login" ghost onClick={onHandleClickLogin}>
              Log In
            </Button>
            <div
              {...dataSource.mobileMenu}
              onClick={() => {
                this.phoneClick();
              }}
            >
              <em />
              <em />
              <em />
            </div>
          </div>
        </div>
      </TweenOne>
    );
  }
}

export default withRouter(Header3);
