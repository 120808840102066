import React from 'react';
// import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
// import QueueAnim from 'rc-queue-anim';
import { Row, Col } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import { getChildrenToRender } from '../utils/index';
import { Link } from "react-router-dom";

const colConfig = {
  md: 24,
  xs: 24,
  name: 'block',
  className: 'newsdd4-block'
}
const cleanup = (input, num) => {
  let content = input ? input.replace(/<.+?>/g, '') : '';
  let len = content.length;
  let str = num ? content.substring(0, num).trim() + (len - num >= 3 ? '...' : '' ) : content
  return str
}

function Detail4(props) {
  const { dataSource, newsSource, isMobile, ...tagProps } = props;
  const { blockWrapper } = dataSource;
  const { result = {}, previous = {}, next = {} } = newsSource || {}
  const detail = [result]
  let titleObj = [
    {
      name: 'title',
      className: 'newsdd4-title-h1',
      children: result.title,
    },
    {
      name: 'titleDate',
      className: 'newsdd4-title-date',
      children: result.created_at,
    },
  ];

  const childrenToRender = detail.map((item, i) => {
    let tempObj = [
      {
        name: 'image',
        className: 'newsdd4-block-image',
        children: item.thumbnail_img
      }
    ]
    return (<Col {...colConfig} key={"news_deail_" + result.id}>
      <div className="newsdd4-block-group">
        <div className="block-text">
            {tempObj.map(getChildrenToRender)}
            {/* <div name="subtitle" className="newsdd4-block-subtitle" dangerouslySetInnerHTML={{__html: item.intro}}></div> */}
            <div name="content" className="newsdd4-block-content" dangerouslySetInnerHTML={{__html: item.content}}></div>
        </div>
        <div className="block-addon">
            {previous ? (<Link className="newsdd4-control" to={{
                pathname: "/newsDetail/" + previous.id
              }}>
              <div className="newsdd4-control-prev">
                <LeftOutlined twoToneColor="#F49800" style={{color: '#F49800'}}/>
              </div>
              <div className="newsdd4-control-detail">
                <div className="newsdd4-control-title">Previous post</div>
                <div className="newsdd4-control-content" dangerouslySetInnerHTML={{__html: cleanup(previous.title, 40)}}></div>
              </div>
              <div className="newsdd4-control-img">
                <img src={previous.thumbnail_img} alt="" />
              </div>
            </Link>) : (<div className="newsdd4-sps">
            </div>) }
            {next ? (<Link className="newsdd4-control" to={{
                pathname: "/newsDetail/" + next.id
              }}>
              <div className="newsdd4-control-img">
                <img src={next.thumbnail_img} alt="" />
              </div>
              <div className="newsdd4-control-detail">
                <div className="newsdd4-control-title">Next post</div>
                <div className="newsdd4-control-content" dangerouslySetInnerHTML={{__html: cleanup(next.title, 40) }}></div>
              </div>
              <div className="newsdd4-control-next">
                <RightOutlined twoToneColor="#F49800" style={{color: '#F49800'}}/>
              </div>
            </Link>) : (<div className="newsdd4-sps">
            </div>)}
        </div>
      </div>
    </Col>)
  });
  return (
    <div {...tagProps} {...dataSource.wrapper}>
      <div {...dataSource.page}>
        <div {...dataSource.titleWrapper}>
          {titleObj.map(getChildrenToRender)}
        </div>
        <div>
          <Row
            {...blockWrapper}
          >
            {childrenToRender}
          </Row>
        </div>
      </div>
    </div>
  );
}

export default Detail4;
